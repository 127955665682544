import axios from "axios";
require("./mock");

export default {
  productList(params) {
    return axios.post("/mock/productList", params);
  },
  orderList(params) {
    return axios.post("/mock/orderList", params);
  },
};
