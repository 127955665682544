const Mock = require("mockjs");
const baseUrl = "http://127.0.0.1:60536";

// 生成模拟数据
const productList = function (params) {
  var body = JSON.parse(params.body);
  var [name, isMarketable, pageIndex, pageSize, total] = [body.name, body.isMarketable, body.pageIndex, body.pageSize, body.total];
  var pageCount = Math.ceil(total / pageSize);
  var data = [];
  var randomStatus = isMarketable == null;
  for (let index = 0; index < pageSize; index++) {
    data.push(
      Mock.mock({
        // 属性id是一个自增数，起始值为 1，每次增 1
        ProductCode: "@id()",
        // 通过占位符生成随机数据
        Name: name ? name : "@name",
        Labels: "@shuffle(['标签1', '标签2', '标签3'], 1, 2)",
        ProductSKUs: "@shuffle(['货品1', '货品2', '货品3'], 1, 2)",
        IsMarketable: randomStatus ? "@boolean" : isMarketable,
        CreateTime: "@date('yyyy-MM-dd HH:mm:ss')",
        UpdateTime: "@date('yyyy-MM-dd HH:mm:ss')",
      })
    );
  }

  return {
    res: 0,
    // 属性list的值是一个数组，其中含有 1 到 10 个元素
    data: {
      Total: total,
      PageCount: pageCount,
      Data: data,
    },
    msg: "",
  };
};

const orderList = function (params) {
  var body = JSON.parse(params.body);
  var [name, status, pageIndex, pageSize, total] = [body.name, body.status, body.pageIndex, body.pageSize, body.total];
  var pageCount = Math.ceil(total / pageSize);
  var data = [];
  var randomStatus = status == -1 || status == "";
  for (let index = 0; index < pageSize; index++) {
    data.push(
      Mock.mock({
        Payer: name ? name : "@name",
        OrderCode: "@id()",
        Price: "@float(0, 100, 0, 5)",
        PayAmount: "@float(0, 100, 0, 5)",
        PayTime: "@date('yyyy-MM-dd HH:mm:ss')",
        "PaymentMethod|1": ["支付宝", "微信"],
        "Status|1": randomStatus ? ["等待付款", "已付款", "已取消", "申请退款", "已退款"] : status,
        CreateTime: "@date('yyyy-MM-dd HH:mm:ss')",
      })
    );
  }

  return {
    res: 0,
    // 属性list的值是一个数组，其中含有 1 到 10 个元素
    data: {
      Total: total,
      PageCount: pageCount,
      Data: data,
    },
    msg: "",
  };
};

// 映射访问的url
// 此处表示当Ajax请求/mock/test路径时，会映射执行test函数
Mock.mock(`${baseUrl}/mock/productList`, productList);
Mock.mock(`${baseUrl}/mock/orderList`, orderList);
